.App {
  text-align: center;
  display: flex;
  font-family: 'avenir';
}

body {
  font-family: 'avenir';
}

/* a:hover {
  transform: scale(1.2);
} */

/* ------------- LANDING PAGE CSS -------------- */

.header-color {
  background: #ee0979;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #ff6a00, #ee0979);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #ff6a00, #ee0979); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.landing-grid {
  background: #ee0979;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #ff6a00, #ee0979);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #ff6a00, #ee0979); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.banner-text h1 {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'avenir';
  font-size: 50px;
  letter-spacing:0.1em
  


  /* overflow: hidden; 
  border-right: .15em solid white; 
  white-space: nowrap; 
  margin: auto;
  letter-spacing: .15em;
  animation: 
    typing 5.0s steps(40, end),
    blink-caret .75s step-end infinite; */
  
}

/* ------------- CONTACT PAGE CSS -------------- */


section {
  height: 100vh;
  width: 100%;
  background-color: #2a2a2a;
  
}

/* .contact-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
} */

.container-contact {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  max-height: 660px;
  padding: 20px;
  box-shadow: 0px 0px 20px #00000010;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: scroll 
}

.container-contact .form-group {
  width: 100%;
  margin-top: 0px;
  font-size: 20px;
}
.container-contact .form-group input,
.form-group textarea {
  width: 100%;
  padding: 5px;
  font-size: 18px;
  border: 1px solid rgba(128, 128, 128, 0.199);
  margin-top: 5px;
}

.container-contact textarea {
  resize: none;
  height: 120px;
  overflow: scroll;
}

.container-contact button[type="submit"] {
  width: 100%;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 24px;
  border-radius: 8px;
  font-family: "Avenir";
  color: rgb(27, 166, 247);
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
  transition: 0.3s ease background-color;
}

.container-contact button[type="submit"]:hover {
  background-color: rgb(214, 226, 236);
}

/* ------------- ABOUT PAGE CSS -------------- */

.body {
  background-color: #2a2a2a;
}

.about-page{
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  /* color: "white"; */
}

.profile-img {
  display: flex;
  height: 100%;
  text-align: left;
  /* offset: 20px; */
}


/* ------------- EXPERIENCE PAGE CSS -------------- */

.demo-card-square.mdl-card {
  width: 320px;
  height: 320px;
}
.demo-card-square > .mdl-card__title {
  color: #fff;
}

.mdl-grid{
  display: flex;
  justify-content: center;
  padding: 30px;
  margin: auto;
  overflow-x: scroll;
}

.project-grid{
  display: flex;
}

.demo-card-wide.mdl-card {
  
  width: 800px;
  max-height: 400px;
  background-color: #2a2a2a;
  box-shadow: 6px 6px rgba(255,255,255,0.1);
  border: 2px solid rgba(255,255,255,0.2);
  
}
.demo-card-wide > .mdl-card__title {
  color: #fff;
  vertical-align: text-top;
}
.demo-card-wide > .mdl-card__menu {
  color: gray;
}

.demo-card-wide > .mdl-card__supporting-text {
  color: white;
  font-size: 17px;
  width: fit-content;
  
}

.demo-card-wide > a {
  color: white;
}

.company-img {
  /* padding: 20px; */
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

/* ------------- COOKING PAGE CSS -------------- */

.carousel-inner > .item > img {
  width:640px;
  height:360px;
}

.carousel-inner {
  overflow: hidden;
}

.carousel-inner > .item > h3 {
  color: black;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
